<template>
    <div class="main" :style="{ backgroundImage: `url(${playerDetailsBg})` }">
        <div class="player_content" :style="{backgroundImage: `url(${$Config.UI_CONFIG.playerDetailHeader})`}">
            <!-- 导航 -->
            <page-head :src="require('@/assets/images/common/nav-back-black.svg')"  class="nav_back" />
            <!-- 基本信息 -->
            <people-header :list="peopleData" peopleType="player"  />
            <!-- 个人信息 -->
            <div class="title_msg">{{titleMsg}}</div>
<!--            其它信息动态表格生成信息-->
            <div class="people_info">
                <div class="people_row card_row">
                    <!-- dynamicFieldArr的声明在 ./mixin/getDynamicForm.js -->
                    <dl v-for="item in dynamicFieldArr" :key="item.id">
                        <dd v-html="peopleData.fields[item.id] || defaultText"></dd>
                        <dt>{{item.name}}</dt>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";
import { defineComponent, onMounted, getCurrentInstance, toRefs, reactive } from 'vue';

export default defineComponent({
    setup() {
        const route = useRoute()
        const { proxy } = getCurrentInstance();
        const { playerDetailsBg, color } = proxy.$Config.UI_CONFIG
        const { id, position,number } = route.query

        const state = reactive({
            playerDetailsBg,
            color,
            peopleData: {},
            defaultText: '-',
            dynamicFieldArr: [], // 接口动态字段
            titleMsg:'个人信息',
        });

        const getDetailsData = async () => {
            const params = {
                id, // 球员id
                dynamic: true
            };
            const { code, data } = await proxy.$server.getUserMsg(params);
            if (code === 200) {
                data.position = proxy.$utils.getKeyValue('playerPositionList', position);
                data.number = number;
                state.peopleData = data;
            }

            // 获取用户动态字段的值
            const valueParams = {
                scene: 'player_intel_screen',
            };
            const res = await proxy.$server.getUser_form_data(valueParams);
            state.dynamicFieldArr = res.data || [];
        };
        onMounted(() => {
            getDetailsData()
        })

        return {
            ...toRefs(state)
        }
    },
})
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    box-sizing: border-box;
    padding: 60px 40px;

    .player_content {
        position: relative;
        border-radius: 20px;
        box-sizing: border-box;
        height:100%;
        width: 100%;
        background-repeat:no-repeat;
        background-position: left top;
        background-size: 100% 400px;
        background-color: #FFFFFF;
        /*返回按钮*/
        .nav_back{
            position: absolute;
            top: 32px;
            left: 32px;
            z-index: 3;
            padding: 0;
        }
        .title_msg{
            position: relative;
            top: 50px;
            bottom: 0;
            left: 46px;
            font-family: PingFangMedium;
            font-size: 36px;
            line-height: 50px;
            color: #834400;
        }
        /*个人信息*/
        .people_info {
            position: absolute;
            top: 460px;
            width: 100%;
            box-sizing: border-box;
            padding-left: 100px;
            height: calc(100% - 460px - 128px);
            overflow: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar{
                display: none;
            }
            .people_row {
                display: flex;
                align-items: flex-end;
                dl {
                    margin-bottom: 40px;
                    color: #333333;
                    width: 100%;
                    dd {
                        font-size: 28px;
                        line-height: 39px;
                        word-break: break-all;
                    }
                    dt {
                        font-size: 24px;
                        line-height: 34px;
                        color: #81818A;
                        padding-top: 8px;
                    }
                }
            }
            .card_row{
                display: flex;
               flex-wrap: wrap;
                >dl{
                   width: calc(100% /4);
                }
            }
        }
    }
}
.pagination{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);

}
::v-deep .pageBtn{
    background: #B7B7BF !important;
    height: 56px !important;
    line-height: 56px !important;
    &.disable {
        opacity: 1 !important;
        background: #F3F5F9 !important;
        color: #B7B7BF !important;
    }
}
</style>
